import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { saveAs } from "file-saver";
import { useState } from "react";
import * as XLSX from "xlsx";

function App() {
  const [images, setImages] = useState([]);
  const [sheetData, setSheetData] = useState(null);
  const [sheetError, setSheetError] = useState(false);
  const [sheetSuccess, setSheetSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePaymentUpload = (e) => {
    setSheetError(false);
    setSheetError(false);
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheet = workbook.SheetNames[0];
      const firstSheetData = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheet]
      );
      const firstSheetDataItem = firstSheetData[0];
      if (
        !firstSheetDataItem["SWIFT банка бенефициара"] ||
        !firstSheetDataItem["Сумма платежа"]
      ) {
        setSheetError(true);
      } else {
        console.log(firstSheetDataItem); // TODO:DELETE DEV LOG
        setSheetData({
          beneficiarySwift: firstSheetDataItem["SWIFT банка бенефициара"],
          beneficiaryAddress: firstSheetDataItem["Адрес бенефициара"],
          beneficiaryBank: firstSheetDataItem["Банк бенефициара"],
          beneficiaryAccountNumber:
            firstSheetDataItem["Номер счета бенефициара в банке"],
          beneficiaryCompany: firstSheetDataItem["Компания бенефициар"],
          beneficiaryCountry: firstSheetDataItem["Страна бенефициар"],
          currency: firstSheetDataItem["Валюта платежа"],
          productCategory: firstSheetDataItem["Категория товаров"],
          payer: firstSheetDataItem["Плательщик"],
          paymentPurpose: firstSheetDataItem["Назначение платежа"],
          total: firstSheetDataItem["Сумма платежа"],
          billNumber: firstSheetDataItem["номер заявки"],
          billDatetime: firstSheetDataItem["Дата и время заявки"],
        });
        console.log({
          beneficiarySwift: firstSheetDataItem["SWIFT банка бенефициара"],
          beneficiaryAddress: firstSheetDataItem["Адрес бенефициара"],
          beneficiaryBank: firstSheetDataItem["Банк бенефициара"],
          beneficiaryAccountNumber:
            firstSheetDataItem["Номер счета бенефициара в банке"],
          beneficiaryCompany: firstSheetDataItem["Компания бенефициар"],
          beneficiaryCountry: firstSheetDataItem["Страна бенефициар"],
          currency: firstSheetDataItem["Валюта платежа"],
          productCategory: firstSheetDataItem["Категория товаров"],
          payer: firstSheetDataItem["Плательщик"],
          paymentPurpose: firstSheetDataItem["Назначение платежа"],
          total: firstSheetDataItem["Сумма платежа"],
          billNumber: firstSheetDataItem["номер заявки"],
          billDatetime: firstSheetDataItem["Дата и время заявки"],
        }); // TODO:DELETE DEV LOG
        setSheetSuccess(true);
      }
    };
  };

  const handleImageUpload = (file) => {
    const imagesUpdated = [...images];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        imagesUpdated.push(reader.result);
        setImages([...imagesUpdated]);
      }
    });
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    if (sheetError) return;
    if (!sheetData) {
      setSheetError(true);
      return;
    }
    setLoading(true);
    createAndDownloadPdf();
  };

  const createAndDownloadPdf = () => {
    axios
      .post("https://invoicer.tw1.ru:5000/create-pdf", {
        print: images,
        sheetData: sheetData,
      })
      .then(() =>
        axios.get("https://invoicer.tw1.ru:5000/fetch-pdf", {
          responseType: "blob",
        })
      )
      .then((res) => {
        setLoading(false);
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "newPdf.pdf");
      });
  };

  if (loading) {
    return (
      <Container className="pt-4 d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="pt-4">
      <Form className="d-flex flex-column">
        <Form.Group className="mb-4">
          <Form.Label>Платежный документ (xlsx)</Form.Label>
          <Form.Control
            type="file"
            onChange={handlePaymentUpload}
            isValid={sheetSuccess}
            isInvalid={sheetError}
          />
          <Form.Control.Feedback>Документ в порядке!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Документ некорректного формата
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Печать (png)</Form.Label>
          <Form.Control
            type="file"
            multiple
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                handleImageUpload(file);
              }
            }}
          />
        </Form.Group>
        <Button className="ms-auto" onClick={handleSubmit}>
          Download PDF
        </Button>
      </Form>
    </Container>
  );
}

export default App;
